import React, { useState } from 'react';
import { useAuth } from '../../core/context/AuthContext';
import { api } from '../../core/services/api';
import { Input, Button } from 'antd';
import { log } from 'console';
import { useNavigate } from 'react-router-dom';


const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();  

    const { login } = useAuth();

    const handleLogin = async () => {
        try {
            const response = await api.post('/auth/login', { email, password }); // Atualize com sua rota de login
    
            const { token, user } = response.data;
            console.log(user.isAdmin,token);
    
            if (user.isAdmin) {
                localStorage.setItem('authToken', token);
                login(user.isAdmin);
                alert("Acesso permitido");
                 navigate('/dashboard');
            } else {
                console.log("erro: usuário não é administrador")
                alert("Erro ao fazer login. Acesso permitido apenas para administradores.");
            }
    
        } catch (error) {
            console.error("Erro ao fazer login:", error);
            alert("Erro ao fazer login. Verifique suas credenciais.");
        }
    }

    return (
        <div style={{ backgroundColor: 'yellow', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={{ width: '300px' }}>
            <label>
              Email:
              <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </label>
      
            <label style={{ marginTop: '20px' }}>
              Senha:
              <Input.Password value={password} onChange={e => setPassword(e.target.value)} />
            </label>
      
            <Button type="primary" onClick={handleLogin} style={{ marginTop: '20px', width: '100%' }}>Entrar</Button>
          </div>
        </div>
      );
}

export default Login;
