import React from 'react';
import {DocAssets} from "../../assets/index";

const PoliticaPrivacidade = () => {
  return (
    <div>
      <iframe src={'/termos.html'} style={{width: '100vw', height: '100vh'}} />
    </div>
  );
};

export default PoliticaPrivacidade;
