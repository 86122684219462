import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs, DatePicker, Select, Input, Alert, Space } from "antd";
import { api } from "../../../core/services/api";
import { SearchOutlined } from '@ant-design/icons';
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  GridFilters,
  Filter,
  FilterLabel,
  Title,
} from "../styles";
import moment from "moment";
import { env } from "../../../enviroment";
import dayjs, { Dayjs } from 'dayjs';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

interface ComponentProps {
    item: any;
    closeModal: () => void;
}

const ModalViewRoomAds: React.FC<ComponentProps> = ({ item, closeModal }) => {

    const [filters, setfilters] = useState<any>({
        dateIni: '',
        dateEnd: '',
        dateTravelIni: '',
        dateTravelEnd: '',
        origemState: '',
        origemCity: '',
        destinationState: '',
        destinationCity: '',
    });

    useEffect(() => {
      // transform item.filters in object
      console.log(item.filter);
      const filtersObject = JSON.parse(item.filter);

      //filters.dateTravelIni.format('DD/MM/YYYY')

      setfilters({
        dateIni: filtersObject.dateIni ? moment(filtersObject.dateIni).format('DD/MM/YYYY'): 'N/A',
        dateEnd: filtersObject.dateEnd ? moment(filtersObject.dateEnd).format('DD/MM/YYYY'): 'N/A',
        dateTravelIni: filtersObject.dateTravelIni ? moment(filtersObject.dateTravelIni).format('DD/MM/YYYY'): 'N/A',
        dateTravelEnd: filtersObject.dateTravelEnd ? moment(filtersObject.dateTravelEnd).format('DD/MM/YYYY'): 'N/A',
        origemState: filtersObject.origemState ?? 'N/A',
        origemCity: filtersObject.origemCity ?? 'N/A',
        destinationState: filtersObject.destinationState ?? 'N/A',
        destinationCity: filtersObject.destinationCity ?? 'N/A',
      });
      console.log(filters);
    }, [item]);
    console.log('item', item)
    return (
        <Modal
          title="Room Ads"
          onCancel={closeModal}
          visible={true}
          className="large-modal" 
          footer={[
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button key="close" onClick={closeModal}>
                Fechar
                </Button>
            </div>
          ]}
        >
        {item && (
          <Container>

            <Col span={24}>
              <Title>Anúncio</Title>
            </Col>

            <Row>
              <Col span={24}>
                <label htmlFor="">Menssagem:</label>
                <Card>
                  <NameContainer>{item.message} <br /> {item.linkTitle && ( <a href={item.link} target="_blank">{item.linkTitle}</a> )}  </NameContainer>
                  {item.photo && (
                    <img src={`${env.apiLocal}room_ads/image/${item.id}`} style={{maxWidth: '50%'}} />
                  )}
                </Card>
              </Col>
            </Row>

            {/* clicks */}
            {/* count sender */}
            <Row>
              <Col span={24}>
                <Title>Dados</Title>
              </Col>
              <Col span={6} style={{textAlign: 'center'}}>
                <label htmlFor="">Data:</label>
                <Card>
                  <NameContainer>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</NameContainer>
                </Card>
              </Col>
              <Col span={6} style={{textAlign: 'center'}}>
                <label htmlFor="">Rooms:</label>
                <Card>
                  <NameContainer>{item.countRooms}</NameContainer>
                </Card>
              </Col>
              <Col span={6} style={{textAlign: 'center'}}>
                <label htmlFor="">Cliques:</label>
                <Card>
                  <NameContainer>{item.countclick}</NameContainer>
                </Card>
              </Col>
              <Col span={6} style={{textAlign: 'center'}}>
                <label htmlFor="">Cliques únicos:</label>
                <Card>
                  <NameContainer>{item.countClicksUnique}</NameContainer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title>Filtros</Title>
              </Col>
              <Col span={6}>
                <label htmlFor="">Data Inicial:</label>
                <Card>
                  <NameContainer>{filters.dateIni}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Data Final:</label>
                <Card>
                  <NameContainer>{filters.dateEnd}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Data Viagem Inicial:</label>
                <Card>
                  <NameContainer>{filters.dateTravelIni}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Data Viagem Final:</label>
                <Card>
                  <NameContainer>{filters.dateTravelEnd}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Estado Origem:</label>
                <Card>
                  <NameContainer>{filters.origemState}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Cidade Origem:</label>
                <Card>
                  <NameContainer>{filters.origemCity}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Estado Destino:</label>
                <Card>
                  <NameContainer>{filters.destinationState}</NameContainer>
                </Card>
              </Col>
              <Col span={6}>
                <label htmlFor="">Cidade Destino:</label>
                <Card>
                  <NameContainer>{filters.destinationCity}</NameContainer>
                </Card>
              </Col>
            </Row>

          </Container>
        )}


        </Modal>
    )
}

export default ModalViewRoomAds;