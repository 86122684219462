import styled from 'styled-components'
import {Button, Layout, Menu} from 'antd'

const {Header, Sider} = Layout
const {Item} = Menu

export const ASide = styled(Sider)`
  position: relative;
  min-height: 100vh;
  background: var(--secondary-color) !important;

  & .ant-layout-sider-trigger {
    background-color: var(--primary-color);
    height: 40px;

    & span svg {
      margin-top: -10px;
      color: #000;
    }
  }
`

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  // height: 110px;
  padding: 20px;
  padding-top: 50px;
  background-color: var(--bg-color);
`

export const GigasusLogo = styled.img`
  width: 100%;
`

export const PersonalInfo = styled.div`
  width: 100%;
  position: relative;
  justify-content: left;
  color: var(--font-color);
  margin-bottom: 10px;
`

export const PersonalInfoContainer = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid rgb(152, 185, 255, 0.4);
`

export const PersonalName = styled.div`
  width: 100%;
  font-size: 0.8em;
  font-weight: bold;
  color:white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const PersonalRole = styled.div`
  width: 100%;
  color:white;

  font-size: 0.6em;
  font-style: italic;
`

export const MenuContainer = styled(Menu)`
  background: transparent;

  &.ant-menu-inline {
    border-right: none;
  }

  // & .ant-menu-item::after {
    // border-right: none !important;
    // border-left: 4px solid var(--secondary-color) !important;
    // margin-left: 1px;
    // border-top-left-radius: 4px;
    // border-bottom-left-radius: 4px;
    // }

  &.ant-menu-inline-collapsed .ant-menu-item {
    & .anticon {
      padding-top: 10px;
    }
  }

  &.ant-menu-inline li:last-child, .ant-menu-inline-collapsed li:last-child {
    margin-bottom: 50px;
  }
  &:not(.ant-menu-horizontal) .ant-menu-item-active {
    background-color: #CCC !important;
  }

  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFF;
    // box-shadow: 0px 0px 15px -5px var(--secondary-color);

    & a, .anticon {
      color: var(--secondary-color);
    }
  }
`

export const MenuItem = styled(Item)`
  //&:hover .anticon {
  //  color: var(--white-color) !important;
  //}

  & .anticon {
    color: var(--primary-color);

    & svg {
      font-size: 22px;
    }
  }

  & a {
    font-family: 'Play', sans-serif;
    color: var(--white-color);
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;
  }

  & a:hover {
    // color: var(--white-color);
  }

  &.ant-menu-item {
    height: 50px;
  }

  &.ant-menu-item-selected span a {
    color: var(--white-color);
    font-weight: bold;
  }

  &.ant-menu-item:not(.ant-menu-item-selected) {
    color: white;
  }
`

export const MainHeader = styled(Header)`
  float: left;
  width: 100%;
  height: 50px !important;
  background-color: var(--secondary-color);

  &.ant-layout-header {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 0 !important;
  }
`

export const HeaderContainer = styled.div`
  float: left;
  width: calc(100% - 80px);
  min-width: 640px;
  height: 30px;
  margin: 10px 40px;
`

export const BoxBread = styled.div`
  float: left;
  max-width: calc(100% - 130px);
  height: 40px;
  padding-left: 30px;
  font-size: 0.8em;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const BoxNotify = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-right: 20px;

  & .message, .notify, .profile {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }

  & .message {
    & svg {
      font-size: 18px;
      color: var(--primary-color);
    }
  }

  & .notify {
    & svg {
      font-size: 20px;
      color: var(--primary-color);
    }
  }

  & .profile {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    border-radius: 50%;

    & svg {
      font-size: 16px;
      color: var(--primary-color);
    }
  }
`

export const MainContent = styled.div`
  width: 100%;

  height: calc(100vh - 50px);
  padding: 30px 40px;
  background-color: var(--bg-color2);
  overflow: hidden scroll;
`

export const BoxContent = styled.div`
  width: 100%;
  padding: 1px;
`

export const ButtonTable = styled(Button)`
  color: #0072BB;
  border: none;
`

export const SmallLogo = styled.img`
  width: 50px ;
  height: 50px;
`;



export const LargeLogo = styled.img`
  width: 100px ;
  height: 100px;
  
  ;
`
