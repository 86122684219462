// ResetPassword.js

import React, { useState, useEffect } from 'react';
import './ResetPassword.css';
import { api } from '../../core/services/api';

const ResetPassword = () => {
  const [isValidToken, setIsValidToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  useEffect(() => {

    if (!token) {
      // Se não houver token, pode tratar da maneira apropriada, como redirecionar ou mostrar uma mensagem de erro
      console.error('Token ausente. Redirecionar ou mostrar mensagem de erro.');
      setIsValidToken(false);
      setLoading(false);
      return;
    }

    // Faça a chamada de API para verificar a validade do token
    const checkTokenValidity = async () => {
      try {
        console.log('Verificando a validade do token...', token);
        ///reset-password/check-token
        const response = await api.post('/users/reset-password/check-token', { token }); // Atualize com sua rota de login
        //const response = await fetch(`sua_api/aqui/verificar-token?token=${token}`);
        //const data = await response.json();
        console.log('Resposta da verificação do token:', response.data);

        if (response.data.success) {
          setIsValidToken(true);
          setLoading(false);
        } else {
          setIsValidToken(false);
          setLoading(false);
          // Se o token não for válido, você pode redirecionar o usuário ou exibir uma mensagem de erro
          console.error('Token inválido. Redirecionar ou mostrar mensagem de erro.');
        }
      } catch (error) {
        console.error('Erro ao verificar o token:', error);
        setIsValidToken(false);
        setLoading(false);
      }
    };

    checkTokenValidity();
  }, []); // O array vazio como segundo parâmetro significa que este useEffect será executado apenas uma vez, sem depender de variáveis

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setIsPasswordMatch(true);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setIsPasswordMatch(true);
  };

  const handleResetPassword = async () => {
    if (password === confirmPassword && password.length > 7 ) {
      // Implemente a lógica de redefinição de senha aqui
      console.log('Redefinição de senha bem-sucedida');
      try{

        const response = await api.post('/users/reset-password/reset', { token, password }); // Atualize com sua rota de login

        if (response.data.success) {
          alert("Senha redefinida com sucesso");
        } else {
          alert("Erro ao redefinir a senha");
        }

      } catch (error) {
        console.error('Erro ao redefinir a senha:', error);
      }
    } else {
      setIsPasswordMatch(false);
    }
  };

  if (loading) {
    return ( 
      <div className="reset-password-container">
        <h1 style={{textAlign: 'center'}}>Validando token...</h1>
      </div>
      ); // Você pode exibir um indicador de carregamento aqui
  }

  if (!isValidToken) {
    return ( 
      <div className="reset-password-container">
        <h1 style={{textAlign: 'center'}}>Token inválido...</h1>
      </div>
      ); // Você pode exibir um indicador de carregamento aqui
  }

  return (
    <div className="reset-password-container">
      <h1>Resetar Senha</h1>
      <div className="password-input">
        <label>Nova Senha:</label>
        <input type="password" value={password} onChange={handlePasswordChange} />
      </div>
      <div className="password-input">
        <label>Confirmar Senha:</label>
        <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
        {!isPasswordMatch && <p className="error-message">As senhas não coincidem ou não tem 8 caracteres</p>}
      </div>
      <button className="reset-button" onClick={handleResetPassword}>
        Resetar Senha
      </button>
    </div>
  );
};

export default ResetPassword;
