import React from 'react';

const RemoveAcount = () => {
  return (
    <div>
      <h1>Remover Conta</h1>
    <h2>Que pena que você quer sair do Myhostel</h2>
      <p>
        Para remover sua conta, envie um email para diego@delbianco.emp.br solicitando a remoção dos seus dados, é importante que seja enviado usando o mesmo email de login do usuario para nos certificarmos da sua autenticidade.
      </p>
      <p>Assim vamos limpar todos os seus dados pessoais, e sua foto de perfil. Alguns registros de uso podem se manter em nosso banco de dados, mas não seram mais relacionados com seus dados pessoais.</p>
    </div>
  );

};

export default RemoveAcount;