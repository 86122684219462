import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs } from "antd";
import { api } from "../../core/services/api";
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  Title,
} from "./styles";
import moment from "moment";
import { env } from "../../enviroment";

const { TabPane } = Tabs;

const Users: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);

  const showModal = (user: any) => {
    setSelectedUser(user);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalVisible(false);
  };

  const handleReject = async (userId: string) => {
    try {
      const response = await api.put(`/users/${userId}/disapprove`);

      if (response.status === 200) {
        const updatedUsers = users.map((user: any) =>
          user.id === userId ? { ...user, approved: false } : user
        );
        setUsers(updatedUsers);

        alert("Usuário reprovado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao reprovar o usuário:", error);
      alert("Erro ao reprovar o usuário. Tente novamente.");
    }
  };

  const handleResolveVideoVerification = async (userId: string, resolution: any) => {
    try {
      const response = await api.post(`/users/${userId}/video-verification/resolve`, { resolution: resolution });

      if (response.status === 200) {
        const updatedUsers = users.map((user: any) =>
          user.id === userId ? { ...user, videoVerified: resolution ? 2 : 1 } : user
        );
        setUsers(updatedUsers);

        alert("Usuário Atualizado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao atualizar o usuário:", error);
      alert("Erro ao atualizar o usuário. Tente novamente.");
    }
  };

  const handleApprove = async (userId: string) => {
    try {
      const response = await api.put(`/users/${userId}/approve`);

      if (response.status === 200) {
        const updatedUsers = users.map((user: any) =>
          user.id === userId ? { ...user, approved: true } : user
        );
        setUsers(updatedUsers);

        alert("Usuário aprovado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao aprovar o usuário:", error);
      alert("Erro ao aprovar o usuário. Tente novamente.");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ação",
      dataIndex: "action",
      render: (text: any, user: any) => {
          return (
            <>
             {!user.approved && (
            <Button
              onClick={() => handleApprove(user.id)}
              style={{ marginLeft: "10px" }}
            >
              Aprovar
            </Button>
          )}
            {!user.rejected && (
            <Button
              onClick={() => handleReject(user.id)}
              style={{ marginLeft: "10px" }}
            >
              Rejeitar
            </Button>
          )}
              <Button
                onClick={() => showModal(user)}
                style={{ marginLeft: "10px" }}
              >
                Ver Detalhes
              </Button>
            </>
          );
      },
    },
  ];

  useEffect(() => {
    api.get("/users/all").then((response: any) => {
      const nonAdminUsers = response.data.filter((user: any) => !user.isAdmin);
      setUsers(nonAdminUsers);
    });
  }, []);

  const approvedUsers = users.filter(
    (user: any) => user.approved && !user.rejected
  );
  const pendingUsers = users.filter(
    (user: any) => !user.approved && !user.rejected
  );
  const rejectedUsers = users.filter((user: any) => user.rejected);

  const videoVerfiedUsers = users.filter((user: any) => (user.videoVerified == 1));

  const selectedUserInitialDate = moment(selectedUser?.initialDate).format(
    "DD/MM/YYYY"
  );
  const selectedUserFinalDate = moment(selectedUser?.finalDate).format(
    "DD/MM/YYYY"
  );

  return (
    <>
      <Tabs defaultActiveKey="2">
        <TabPane tab="Em Análise" key="2">
          <Table dataSource={pendingUsers} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Aprovados" key="1">
          <Table dataSource={approvedUsers} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Reprovados" key="3">
          <Table dataSource={rejectedUsers} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Agr apro Video" key="4">
          <Table dataSource={videoVerfiedUsers} columns={columns} rowKey="id" />
        </TabPane>
      </Tabs>

      <Modal
        title="Detalhes do Usuário"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Fechar
          </Button>,
        ]}
      >
        {selectedUser && (
          <Container>
            <Col span={24}>
              <Title>Perfil</Title>
              <ProfilePhotoContainer>
                <ProfilePhoto src={`${env.api}users/${selectedUser.id}/photo`} />
              </ProfilePhotoContainer>
            </Col>

            {selectedUser.videoVerified == 1 && (
              <Row>
              <Col span={24}>
                <label htmlFor="">Video e foto para verificação: </label>
                <Card>
                  <video width='100%' controls >
                        <source src={`${env.api}users/${selectedUser.id}/video-verification`} type="video/mp4"/>
                  </video>
                  <img src={`${env.api}users/${selectedUser.id}/photo-verification`} style={{width: '100%'}} />
                  <Col  style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => handleResolveVideoVerification(selectedUser.id, true)}
                    style={{ margin: "10px", backgroundColor: "#a8ffc7" }}
                  >
                    Aprovar
                  </Button>
                  <Button
                    onClick={() => handleResolveVideoVerification(selectedUser.id, false)}
                    style={{ margin: "10px", backgroundColor: "#ffa8a8" }}
                  >
                    Negar
                  </Button>
                  </Col>
                </Card>
              </Col>
            </Row>
            )}
            {selectedUser.videoVerified == 2 && (
              <Row>
                <Col span={24}>
                  <label htmlFor="">Video para verificação: </label>
                  <Card>
                    <p>O vídeo foi aprovado!</p>
                    <Button
                      onClick={() => window.open(`${env.api}users/${selectedUser.id}/video-verification`, '_blank')}
                      style={{ margin: "10px", backgroundColor: "#a8ffc7" }}
                    >
                      Exibir vídeo
                    </Button>
                  </Card>
                </Col>
              </Row>
            )}

            <Col span={24}>
              <label htmlFor="">Email:</label>
              <Card>
                <NameContainer>{selectedUser.email}</NameContainer>
              </Card>
            </Col>

            <Col span={24}>
              <label htmlFor="">Nome:</label>
              <Card>
                <NameContainer>{selectedUser.name}</NameContainer>
              </Card>
            </Col>

            <Row>
              <Col span={12}>
                <label htmlFor="">Idade: </label>
                <Card>
                  <NameContainer>{selectedUser.birthYear}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Room:</label>
                <Card>
                  <NameContainer>{selectedUser.id}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Messagem Chave:</label>
                <Card>
                  <NameContainer>{selectedUser.description}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Cidade de origem:</label>
                <Card>
                  <NameContainer>{selectedUser.city}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">UF de origem:</label>
                <Card>
                  <NameContainer>{selectedUser.state}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Cidade de Destino:</label>
                <Card>
                  <NameContainer>{selectedUser.destinationCity}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">UF de Destino:</label>
                <Card>
                  <NameContainer>{selectedUser.destinationState}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">De:</label>
                <Card>
                  <NameContainer>{selectedUserInitialDate}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Até:</label>
                <Card>
                  <NameContainer>{selectedUserFinalDate}</NameContainer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor="">Intro:</label>
                <Card>
                  <NameContainer>{selectedUser.intro}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Profissão:</label>
                <Card>
                  <NameContainer>{selectedUser.profession}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Vibes:</label>
                <Card>
                  <NameContainer>{selectedUser.vibes}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Indicações:</label>
                <Card>
                  <NameContainer>0</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Links : </label>
                <Card>
                  <NameContainer>
                      <span>Instagram: {selectedUser.instagram}</span> <br />
                      <span>Linkedin: {selectedUser.linkedin}</span> <br />
                      <span>Facebook: {selectedUser.facebook}</span> <br />
                  </NameContainer>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </Modal>
    </>
  );
};

export default Users;
