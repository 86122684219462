import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import MainLayout from '../../components/Header/layout'
import Dashboard from '../../view/Dashboard'
import UserList from '../../view/Users'
import Logs from '../../view/Logs'
import { useAuth } from '../context/AuthContext'
import Login from '../../view/Login'
import UsersAdmin from '../../view/AdminUsers'
import Complaint from '../../view/Complaint'
import RoomAds from '../../view/RoomAds'
import Politica from '../../view/Politica'
import RemoveAcount from '../../view/RemoveAcount'
import ResetPassword from '../../view/ResetPassword'


const AppRoutes: React.FC = () => {
     const {isLogged} = useAuth()

    return (
      <>
        <Routes> {/* Rotas publicas*/}
            <Route path="/politica-de-privacidade" element={<Politica/>} />
            <Route path="/remover-conta" element={<RemoveAcount/>} />
            <Route path="/reset-password" element={<ResetPassword/>} />
        </Routes>
        {(isLogged) ? (
          <MainLayout>
              <Routes>
                <Route path={"/login"} element={<Navigate to="/dashboard" replace />}/>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path={"/dashboard"} element={<Dashboard/>}/>
                <Route path={"/user-list"} element={<UserList/>}/>
                <Route path={"/admin-users"} element={<UsersAdmin/>}/>
                <Route path={"/complaint"} element={<Complaint/>}/>
                <Route path={"/room-ads"} element={<RoomAds/>}/>
                <Route path={"/logs"} element={<Logs/>}/>
              </Routes>
          </MainLayout>
        ) : (
          <Routes>
            <Route path={"/login"} element={<Login/>}/>
                <Route path={"/dashboard"} element={<Navigate to="/login" replace />}/>
                <Route path={"/user-list"} element={<Navigate to="/login" replace />}/>
                <Route path={"/admin-users"} element={<Navigate to="/login" replace />}/>
                <Route path={"/complaint"} element={<Navigate to="/login" replace />}/>
                <Route path={"/room-ads"} element={<Navigate to="/login" replace />}/>
                <Route path={"/logs"} element={<Navigate to="/login" replace />}/>
          </Routes>
        )}
      </>
    )
}

export default AppRoutes
