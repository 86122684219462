import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs, DatePicker, Select, InputNumber } from "antd";
import { api } from "../../core/services/api";
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  GridFilters,
  Filter,
  FilterLabel,
  Title,
} from "./styles";
import moment from "moment";
import { env } from "../../enviroment";
import { PlusCircleOutlined } from "@ant-design/icons";
import ModalCreateRoomAds from "./components/modalCreateRoomAds";
import ModalViewRoomAds from "./components/modalViewRoomsAds";

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const RoomAds: React.FC = () => {
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalViewVisible, setIsModalViewVisible] = useState(false);
  const [list, setList] = useState<any>([]);
  const [currentItem, setCurrentItem] = useState<any>(null);

  const showModalView = (item: any) => {
    setCurrentItem(item);
    setIsModalViewVisible(true);
  };
  const showModalCreate = () => {
    setIsModalCreateVisible(true);
  };

  const closeModalView = () => {
    setCurrentItem(null);
    setIsModalViewVisible(false);
  };

  const closeModalCreate = () => {
    setIsModalCreateVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Descrição",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Ação",
      dataIndex: "action",
      render: (text: any, item: any) => {
          return (
            <>
              <Button
                onClick={() => showModalView(item)}
                style={{ marginLeft: "10px" }}
              >
                Ver Detalhes
              </Button>
            </>
          );
      },
    },
  ];

  const loadList = () => {
    api.get("/room_ads/list").then((response: any) => {
      setList(response.data);
    });
  }

  useEffect(() => {
    loadList();
  }, []);


  const selectedUserInitialDate = moment(currentItem?.userComplain?.initialDate).format(
    "DD/MM/YYYY"
  );
  const selectedUserFinalDate = moment(currentItem?.userComplain?.finalDate).format(
    "DD/MM/YYYY"
  );

  return (
    <>
      <div style={{width: '100%', textAlign: 'right'}}>
        <Button type="primary" onClick={showModalCreate}>
          <PlusCircleOutlined /> Novo disparo
        </Button>
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Todos" key="1">
          <Table dataSource={list} columns={columns} rowKey="id" />
        </TabPane>
      </Tabs>

      {isModalCreateVisible && (
        <ModalCreateRoomAds closeModal={closeModalCreate} reload={loadList} />
      )}
      {isModalViewVisible && (
        <ModalViewRoomAds closeModal={closeModalView} item={currentItem} />
      )}
    </>
  );
};

export default RoomAds;
