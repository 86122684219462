// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ResetPassword.css */
body{

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  }
  
  .password-input {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error-message {
    color: #d9534f;
    margin-top: 5px;
  }
  
  .reset-button {
    background-color: #428bca;
    color: #fff;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #3071a9;
  }
  `, "",{"version":3,"sources":["webpack://./src/view/ResetPassword/ResetPassword.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;;EAEE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;AACf;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* ResetPassword.css */\nbody{\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100vh;\n}\n.reset-password-container {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); \n  }\n  \n  .password-input {\n    margin-bottom: 15px;\n  }\n  \n  label {\n    display: block;\n    font-size: 14px;\n    margin-bottom: 5px;\n  }\n  \n  input {\n    width: 100%;\n    padding: 8px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .error-message {\n    color: #d9534f;\n    margin-top: 5px;\n  }\n  \n  .reset-button {\n    background-color: #428bca;\n    color: #fff;\n    padding: 10px 15px;\n    font-size: 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .reset-button:hover {\n    background-color: #3071a9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
