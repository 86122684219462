import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from '.';

const MyHostelApp: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </>
    )
}

export default MyHostelApp;