import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs, Form, Input, Space } from "antd";
import { DeleteOutlined, EyeOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { api } from "../../core/services/api";
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  Title,
} from "./styles";
import moment from "moment";

const { TabPane } = Tabs;

const UsersAdmin: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  



  const showModal = (user: any) => {
    setSelectedUser(user);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalVisible(false);
  };

  const handleCreateUser = async (values: any) => {
    try {
      const response = await api.post('/users/admin', {
        name: values.name,
        email: values.email,
        password: values.password,
        nickname: 'defaultNickname', 
        lastName: 'defaultLastName', 
      });
  
      if (response.status === 201) {
        setUsers([...users, response.data]);
        setIsCreateUserModalVisible(false);
      } else if (response.status === 400) {
        alert(response.data.error);
      }
    } catch (error) {
      console.error("Erro ao criar o usuário:", error);
      alert("Erro ao criar o usuário. Tente novamente.");
    }
  };

 
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text: any, record: any) => (
        <Space size="middle">
        <EyeOutlined onClick={() => showModal(record)} />
        <DeleteOutlined onClick={() => handleDelete(record.id)} />
        <CheckCircleOutlined 
          onClick={() => handleToggleActive(record.id)} 
          style={{ color: record.isActive ? 'green' : 'red' }}
        />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    api.get("/users/all").then((response: any) => {
      const adminUsers = response.data.filter((user: any) => user.isAdmin === true);
      setUsers(adminUsers);
    });
  }, []);



  const handleDelete = (userId: string) => {
    Modal.confirm({
      title: 'Deseja realmente deletar o usuário?',
      onOk: async () => {
        try {
          await api.delete(`/users/${userId}`);
          setUsers(users.filter((user: any) => user.id !== userId));
        } catch (error) {
          console.error("Erro ao deletar o usuário:", error);
          alert("Erro ao deletar o usuário. Tente novamente.");
        }
      },
    });
  };
  const handleToggleActive = async (userId: string) => {
    const user = users.find((user: any) => user.id === userId);
    if (!user) return;
  
    try {
      await api.put(`/users/${userId}`, { isActive: !user.isActive });
      setUsers(users.map((user: any) => user.id === userId ? { ...user, isActive: !user.isActive } : user));
    } catch (error) {
      console.error("Erro ao alterar o status do usuário:", error);
      alert("Erro ao alterar o status do usuário. Tente novamente.");
    }
  };

  return (
    <>
    <Button onClick={() => setIsCreateUserModalVisible(true)}>+</Button>

    <Table columns={columns} dataSource={users} />


    <Modal
  title="Detalhes do Usuário"
  visible={isModalVisible}
  onCancel={closeModal}
  footer={[
    <Button key="close" onClick={closeModal}>
      Fechar
    </Button>,
  ]}
>
  {selectedUser && (
    <>
      <p><strong>Nome:</strong> {selectedUser.name}</p>
      <p><strong>Email:</strong> {selectedUser.email}</p>
      
    </>
  )}
</Modal>

    <Modal
      title="Criar Usuário"
      visible={isCreateUserModalVisible}
      onCancel={() => setIsCreateUserModalVisible(false)}
    >
      <Form onFinish={handleCreateUser}>
        <Form.Item name="name" rules={[{ required: true }]}>
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password placeholder="Senha" />
        </Form.Item>
        <Form.Item name="confirmPassword" rules={[{ required: true }]}>
          <Input.Password placeholder="Repetir Senha" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Criar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  </>
  );
};

export default UsersAdmin;