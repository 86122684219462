import { styled } from "styled-components";

export const Grid5 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  grid-gap: 20px;
  margin-bottom: 20px;
  color: var(--text-color);

  & h3{
    text-align: center;
    font-size:  25px;
    color: var(--text-color);
  }

  & h3 span{
    font-size:  18px;
  }

  & .ant-card-head-title{
    color: var(--text-color);
  }
`

export const Grid3 = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  justify-content: center;
  grid-gap: 20px;
  justify-items: center;
  color: var(--text-color);

  & .ant-card-head-title{
    color: var(--text-color);
  }
`

export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  justify-content: center;
  grid-gap: 20px;
  margin-bottom: 20px;
  color: var(--text-color);

  & .ant-card-head-title{
    color: var(--text-color);
  }
`

export const Grid1 = styled.div`
  grid-template-columns: minmax(0, 1fr);
  margin-bottom: 20px;
  justify-content: center;
  grid-gap: 20px;
  color: var(--text-color);
  & .ant-card{
  }
  & .ant-card-body{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & .ant-card-head-title{
    color: var(--text-color);
  }
  `

export const GridFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  justify-content: center;
  margin: auto;
  grid-gap: 20px;
  color: var(--text-color);

  & .ant-card-head-title{
    color: var(--text-color);
  }
    `

export const Filter = styled.div``

export const FilterLabel = styled.div``

export const DailyMessagesNumber = styled.div`
  text-align: center;
  font-size:  5rem;
  color: var(--primary-color);
  font-family: 'Play', sans-serif;
  `


export const Title = styled.div`
  text-align: left;
  font-size:  40px;
  padding-bottom: 30px;
  color: var(--text-color);
  font-family: 'Play', sans-serif;
  `

export const TitleSec = styled.div`
  text-align: left;
  font-size:  30px;
  padding-bottom: 15px;
  color: var(--text-color);
  font-family: 'Play', sans-serif;
  `