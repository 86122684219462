import { createContext, useContext, useState, ReactNode } from 'react';

type AuthContextData = {
    isLogged: boolean;
    isAdmin: boolean;
    login: (isAdmin: boolean) => void;
    logout: () => void;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLogged, setIsLogged] = useState(!!localStorage.getItem('authToken')); 
    const [isAdmin, setIsAdmin] = useState(false);

    const login = (isAdmin: boolean) => {

        setIsAdmin(isAdmin);
        setIsLogged(true);
    }

    const logout = () => {
        setIsLogged(false);
        setIsAdmin(false);
        localStorage.removeItem('authToken'); 
    }

    return (
        <AuthContext.Provider value={{ isLogged, isAdmin, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    return context;
};