import { ConfigProvider } from "antd";

import React from "react";
import "./App.css";
import GigasusRoutes from "./core/routes";
import AppRoutes from "./core/routes";
import MyHostelApp from "./core/routes/myhostel.routes";
import { AuthProvider } from "./core/context/AuthContext";

function App() {
  return (
    <>
      <AuthProvider>
        <ConfigProvider>
          <MyHostelApp />
        </ConfigProvider>
      </AuthProvider>
    </>
  );
}

export default App;
