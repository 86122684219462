import React, { useState, useEffect } from "react";
import { Card, DatePicker, Select, InputNumber, Button } from "antd";
import { Grid1, Grid2, Grid3, Grid5, Title, TitleSec, GridFilters, Filter, FilterLabel } from "./styles";
import { api } from "../../core/services/api";
import { SearchOutlined } from '@ant-design/icons';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement } from 'chart.js';
import { Pie, Bar, Line } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;
ChartJS.register(
  ArcElement, 
  Tooltip, 
  Legend, 
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
  );


const Dashboard: React.FC = () => {

  const iniDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
  const iniEndDate = dayjs().format('YYYY-MM-DD');

  const [dataFilter, setDataFilter] = useState<any>({ini: iniDate, end: iniEndDate});
  const [travelDataFilter, setTravelDataFilter] = useState<any>({ini: '', end: ''});
  const [destinationDataFilter, setDestinationDataFilter] = useState<any>('');
  const [origemDataFilter, setOrigemDataFilter] = useState<any>('');
  const [filters, setFilters] = useState<any>({
    dateIni: iniDate,
    dateEnd: iniEndDate,
    dateTravelIni: '',
    dateTravelEnd: '',
    origemState: '',
    origemCity: '',
    destinationState: '',
    destinationCity: '',
  });

  const [origemFilter, setOrigemFilter] = useState<any>([
    {
      value: '',
      label: 'Todos',
    },
  ]);

  const [destinationFilter, setDestinationFilter] = useState<any>([
    {
      value: '',
      label: 'Todos',
    },
  ]);

  const [chartData, setChartData] = useState<any>({
    age: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    origem_uf: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    origem_city: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    destino_uf: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    destino_city: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    matchs: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    messages: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    users: {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [], 
      }]
    },
    matches_totals: '0',
  });



  useEffect(() => {
    
    console.log(filters);

    api.get("metrics/origem")
      .then((response:any) => {
        const apiData = response.data; 
        const origemData:any = [];
        var lastState = '';
        apiData.forEach((index:any, value:any) => {
          if(!(index.city === null) && !(index.state === null)) {
            if (index.state !== lastState) {
              origemData.push({value: index.state, label: index.state});
              lastState = index.state;
            }
            origemData.push({value: index.state + "-" + index.city, label: index.state + " > " + index.city});
          }
        });
        setOrigemFilter(origemData);
      });

    api.get("metrics/destinations")
      .then((response:any) => {
        const apiData = response.data; 
        const destionationData:any = [];
        var lastState = '';
        apiData.forEach((index:any, value:any) => {
          if(!(index.city === null) && !(index.state === null)) {
            if (index.state !== lastState) {
              destionationData.push({value: index.state, label: index.state});
              lastState = index.state;
            }
            destionationData.push({value: index.state + "-" + index.city, label: index.state + " > " + index.city});
          }
        });
        setDestinationFilter(destionationData);
      });

    api.get("metrics/get", { params: filters })
      .then((response:any) => {
        const apiData = response.data;  
        var ufsData:any = {};

        // no repeat colors
        const random_colors = [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ];

        const random_color_border = [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
        ];
        /*
        -users_totals_by_date
        - users_totals_by_uf
        - users_totals_by_city
        - users_totals_by_state_destination
        - users_totals_by_city_destination
        - users_totals_by_age
        matches_totals_by_date
        -users_totals
        -matches_totals
        user_views_totals_by_date
        user_views_totals
        chat_messages_totals_by_date
        chat_messages_totals
        */

        if (apiData.users_totals_by_uf) {
          const startDate = new Date(dataFilter.ini == '' ? `${iniDate} GMT-0300` : `${dataFilter.ini} GMT-0300`);
          const endDate = new Date(dataFilter.end == '' ? `${iniEndDate} GMT-0300` : `${dataFilter.end} GMT-0300`);

          apiData.users_totals_by_uf.forEach((index:any, value:any) => {
              ufsData[index.state] = index.count;
          });
          const labels_users_totals_by_uf = Object.keys(ufsData);
          const counts_users_totals_by_uf = Object.values(ufsData);

          ufsData = {};
          apiData.users_totals_by_city.forEach((index:any, value:any) => {
            ufsData[index.city] = index.count;
          });
          const labels_users_totals_by_city = Object.keys(ufsData);
          const counts_users_totals_by_city = Object.values(ufsData);

          ufsData = {};
          apiData.users_totals_by_state_destination.forEach((index:any, value:any) => {
            ufsData[index.destinationState] = index.count;
          });
          const labels_users_totals_by_state_destination = Object.keys(ufsData);
          const counts_users_totals_by_state_destination = Object.values(ufsData);

          ufsData = {};
          apiData.users_totals_by_city_destination.forEach((index:any, value:any) => {
            ufsData[index.destinationCity] = index.count;
          });
          const labels_users_totals_by_city_destination = Object.keys(ufsData);
          const counts_users_totals_by_city_destination = Object.values(ufsData);

          ufsData = {};
          apiData.users_totals_by_age.forEach((index:any, value:any) => {
            ufsData[index.age] = index.count;
          });
          const labels_users_totals_by_age = Object.keys(ufsData);
          const counts_users_totals_by_age = Object.values(ufsData);

          
          ufsData = {};
          ufsData['matches_totals_by_date'] = {};
          apiData.matches_totals_by_date.forEach((index:any, value:any) => {
            ufsData['matches_totals_by_date'][index.created] = index.count;
          });

          ufsData['users_totals_by_date'] = {};
          apiData.users_totals_by_date.forEach((index:any, value:any) => {
            ufsData['users_totals_by_date'][index.created] = index.count;
          });

          ufsData['user_views_totals_by_date'] = {};
          apiData.user_views_totals_by_date.forEach((index:any, value:any) => {
            ufsData['user_views_totals_by_date'][index.created] = index.count;
          });

          ufsData['chat_messages_totals_by_date'] = {};
          apiData.chat_messages_totals_by_date.forEach((index:any, value:any) => {
            ufsData['chat_messages_totals_by_date'][index.created] = index.count;
          });

          ufsData['room_messages_totals_by_date'] = {};
          apiData.room_messages_totals_by_date.forEach((index:any, value:any) => {
            ufsData['room_messages_totals_by_date'][index.created] = index.count;
          });


          const matches_totals_by_date_daysArray: { [key: string]: number } = {};
          const users_totals_by_date_daysArray: { [key: string]: number } = {};
          const user_views_totals_by_date_daysArray: { [key: string]: number } = {};
          const chat_messages_totals_by_date_daysArray: { [key: string]: number } = {};
          const room_messages_totals_by_date_daysArray: { [key: string]: number } = {};

          let currentDate = new Date(startDate);
          while (currentDate <= endDate) {

            var year = currentDate.getFullYear();
            var day = String(currentDate.getDate()).padStart(2, '0');
            var month = currentDate.toLocaleString('pt-BR', { month: 'short' });
            var month_n = String(currentDate.getMonth() + 1).padStart(2, '0');

            matches_totals_by_date_daysArray[day+'/'+month] = 
              ufsData['matches_totals_by_date'][`${year}-${month_n}-${day}`] === undefined ? 
                0 : ufsData['matches_totals_by_date'][`${year}-${month_n}-${day}`];

            users_totals_by_date_daysArray[day+'/'+month] = 
              ufsData['users_totals_by_date'][`${year}-${month_n}-${day}`] === undefined ? 
                0 : ufsData['users_totals_by_date'][`${year}-${month_n}-${day}`];
            
            user_views_totals_by_date_daysArray[day+'/'+month] = 
              ufsData['users_totals_by_date'][`${year}-${month_n}-${day}`] === undefined ? 
                0 : ufsData['users_totals_by_date'][`${year}-${month_n}-${day}`];
            
            chat_messages_totals_by_date_daysArray[day+'/'+month] = 
              ufsData['chat_messages_totals_by_date'][`${year}-${month_n}-${day}`] === undefined ? 
                0 : ufsData['chat_messages_totals_by_date'][`${year}-${month_n}-${day}`];
            
            room_messages_totals_by_date_daysArray[day+'/'+month] = 
              ufsData['room_messages_totals_by_date'][`${year}-${month_n}-${day}`] === undefined ? 
                0 : ufsData['room_messages_totals_by_date'][`${year}-${month_n}-${day}`];
  

            currentDate.setDate(currentDate.getDate() + 1);
          }
          
          
          const labels_users_totals_by_date = Object.keys(users_totals_by_date_daysArray);
          const counts_users_totals_by_date = Object.values(users_totals_by_date_daysArray);
          const labels_matches_totals_by_date = Object.keys(matches_totals_by_date_daysArray);
          const counts_matches_totals_by_date = Object.values(matches_totals_by_date_daysArray);
          const labels_user_views_totals_by_date = Object.keys(user_views_totals_by_date_daysArray);
          const counts_user_views_totals_by_date = Object.values(user_views_totals_by_date_daysArray);
          const labels_chat_messages_totals_by_date = Object.keys(chat_messages_totals_by_date_daysArray);
          const counts_chat_messages_totals_by_date = Object.values(chat_messages_totals_by_date_daysArray);
          const labels_room_messages_totals_by_date = Object.keys(room_messages_totals_by_date_daysArray);
          const counts_room_messages_totals_by_date = Object.values(room_messages_totals_by_date_daysArray);
          

          setChartData({
            origem_uf: {
              labels: labels_users_totals_by_uf,
              datasets: [{
                data: counts_users_totals_by_uf,
                backgroundColor: random_colors,
                borderColor: random_color_border,
                borderWidth: 1,
              }]
            },

            origem_city: {
              labels: labels_users_totals_by_city,
              datasets: [{
                data: counts_users_totals_by_city,
                backgroundColor: random_colors, 
                borderColor: random_color_border,
                borderWidth: 1,
              }]
            },

            destino_uf: {
              labels: labels_users_totals_by_state_destination,
              datasets: [{
                data: counts_users_totals_by_state_destination,
                backgroundColor: random_colors, 
                borderColor: random_color_border,
                borderWidth: 1,
              }]
            },

            destino_city: {
              labels: labels_users_totals_by_city_destination,
              datasets: [{
                data: counts_users_totals_by_city_destination,
                backgroundColor: random_colors, 
                borderColor: random_color_border,
                borderWidth: 1,
              }]
            },

            age: {
              labels: labels_users_totals_by_age,
              datasets: [{
                data: counts_users_totals_by_age,
                backgroundColor: random_colors, 
                borderColor: random_color_border,
                borderWidth: 1,
              }]
            },

            matchs: {
              responsive: true,
              labels: labels_matches_totals_by_date,
              datasets: [{
                label: 'Matchs',
                data: counts_matches_totals_by_date,
                backgroundColor: ['rgba(54, 162, 235, 0.2)'], 
                borderColor: ['rgba(54, 162, 235, 0.6)'],
                borderWidth: 1,
              },{
                label: 'Cards',
                data: counts_user_views_totals_by_date,
                backgroundColor: ['rgba(255, 99, 132, 0.2)'], 
                borderColor: ['rgba(255, 99, 132, 0.6)'],
                borderWidth: 1,
              }]
            },

            messages: {
              responsive: true,
              labels: labels_chat_messages_totals_by_date,
              datasets: [{
                label: 'Diretas',
                data: counts_chat_messages_totals_by_date,
                backgroundColor: ['rgba(54, 162, 235, 0.2)'], 
                borderColor: ['rgba(54, 162, 235, 0.6)'],
                borderWidth: 1,
              },{
                label: 'Room',
                data: counts_room_messages_totals_by_date,
                backgroundColor: ['rgba(255, 99, 132, 0.2)'], 
                borderColor: ['rgba(255, 99, 132, 0.6)'],
                borderWidth: 1,
              }]
            },

            users: {
              responsive: true,
              labels: labels_users_totals_by_date,
              datasets: [{
                label: 'Cadastros',
                data: counts_users_totals_by_date,
                backgroundColor: ['rgba(255, 99, 132, 0.2)'], 
                borderColor: ['rgba(255, 99, 132, 0.6)'],
                borderWidth: 1,
              }]
            },
            matches_totals: apiData.matches_totals[0].count,
            users_totals: apiData.users_totals[0].count,
            user_views_totals: apiData.user_views_totals[0].count,
            chat_messages_totals: apiData.chat_messages_totals[0].count,
            room_messages_totals: apiData.room_messages_totals[0].count,
          });



        } else {
          console.error("Error: Unexpected data format from the API.");
        }
      })
      .catch((error) => {
        console.error("Error fetching the pie chart data:", error);
      });
  }, [filters]);



  const onChangeDestination = (value: string) => {
    setDestinationDataFilter(value);
  };

  const onChangeOrigem = (value: string) => {
    setOrigemDataFilter(value);
  }
  
  const onSearch = (value: string) => {
    console.log('search:', value);
  };
  
  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  const selectFilters = async () => {

    var date = dataFilter;
    var travel_date = travelDataFilter;
    var origem = origemDataFilter;
    var destination = destinationDataFilter;

    // faz o split da origem separando por '-' para pegar o estado e a cidade
    var origemCity = '';
    var origemState = '';
    
    if (origem !== '' && origem !== undefined) {
      var origem_split = origem.split('-');
      if (origem_split.length > 1) {
        origemState = origem_split[0];
        origemCity = origem_split[1];
      }else{
        origemState = origem_split[0];
      }
    }

    // faz o split do destino separando por '-' para pegar o estado e a cidade
    var destinationCity = '';
    var destinationState = '';
    if (destination !== '' && destination !== undefined) {
      var destination_split = destination.split('-');
      if (destination_split.length > 1) {
        destinationState = destination_split[0];
        destinationCity = destination_split[1];
      }
    }

    setFilters({
      dateIni: date.ini,
      dateEnd: date.end,
      dateTravelIni: travel_date.ini,
      dateTravelEnd: travel_date.end,
      origemState: origemState,
      origemCity: origemCity,
      destinationState: destinationState,
      destinationCity: destinationCity,
    })
  }
  function handleDateChange(dates: any, dateStrings: [string, string]) {
    if(dates === null) {
      setDataFilter({ini: iniDate, end: iniEndDate});
    }else{
      const startDate = new Date(dates[0]);
      const endDate = new Date(dates[1]);

      const ini_year = startDate.getFullYear();
      const ini_month = String(startDate.getMonth() + 1).padStart(2, '0');
      const ini_day = String(startDate.getDate()).padStart(2, '0');

      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, '0');
      const end_day = String(endDate.getDate()).padStart(2, '0');

      setDataFilter({ini: `${ini_year}-${ini_month}-${ini_day}`, end: `${end_year}-${end_month}-${end_day}`});
    }
  }
  function handleTravelDateChange(dates: any, dateStrings: [string, string]) {
    if(dates === null) {
      setTravelDataFilter({ini: '', end: ''});
    }else{
      const startDate = new Date(dates[0]);
      const endDate = new Date(dates[1]);

      const ini_year = startDate.getFullYear();
      const ini_month = String(startDate.getMonth() + 1).padStart(2, '0');
      const ini_day = String(startDate.getDate()).padStart(2, '0');

      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, '0');
      const end_day = String(endDate.getDate()).padStart(2, '0');

      setTravelDataFilter({ini: `${ini_year}-${ini_month}-${ini_day}`, end: `${end_year}-${end_month}-${end_day}`});
    }
  }

  return (
    <>
      <Title>Painel Myhostel</Title>
      <Card
        bordered={false}
        style={{ width: '100%', marginBottom: '35px' }}
      >
        <GridFilters>
          <Filter>
            <FilterLabel>Data do registro:</FilterLabel>
            <RangePicker 
              onChange={handleDateChange} />
          </Filter>
          <Filter>
            <FilterLabel>Viaja em:</FilterLabel>
            <RangePicker 
              onChange={handleTravelDateChange} />
          </Filter>
          <Filter>
            <FilterLabel>Origem:</FilterLabel>
            <Select
              showSearch
              id={'origem'}
              placeholder="Todos"
              optionFilterProp="children"
              onChange={onChangeOrigem}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{width: '100%'}}
              options={origemFilter}
            />
          </Filter>
          <Filter>
            <FilterLabel>Destino:</FilterLabel>
            <Select
              showSearch
              id={'destination'}
              placeholder="Todos"
              optionFilterProp="children"
              onChange={onChangeDestination}
              onSearch={onSearch}
              filterOption={filterOption}
              style={{width: '100%'}}
              options={destinationFilter}
            />
          </Filter>
          <Filter>
            <Button onClick={selectFilters} style={{width: "100%", marginTop: 20}} type="primary" icon={<SearchOutlined />}>Atualizar</Button>
          </Filter>
        </GridFilters>
      </Card>
      <TitleSec>Métricas</TitleSec>
      <div style={{ display: "flex", flexDirection: 'column' }}>
        
        <Grid5>
          <Card bordered={false}>
            <h3><span>Usuários:</span><br/>{chartData.users_totals}</h3>
          </Card>
          <Card bordered={false}>
            <h3><span>Matchs:</span><br/>{chartData.matches_totals}</h3>
          </Card>
          <Card bordered={false}>
            <h3><span>Cards:</span><br/>{chartData.user_views_totals}</h3>
          </Card>
          <Card bordered={false}>
            <h3><span>Mensagens:</span><br/>{chartData.chat_messages_totals}</h3>
          </Card>
          <Card bordered={false}>
            <h3><span>Msgs em room:</span><br/>{chartData.room_messages_totals}</h3>
          </Card>
        </Grid5>

        <Grid1>
          <Card
            title="Usuários Cadastrados"
            bordered={false}
          >
            <Line height={'300px'} data={chartData.users} options={{ maintainAspectRatio: false }} />
          </Card>
        </Grid1>

        <Grid2>
          <Card title="Matchs X Cards" bordered={false} >
            <Line height={'200px'} data={chartData.matchs} options={{ maintainAspectRatio: false }} />
          </Card>
          <Card title="Mensagens (Diretas X Room)" bordered={false} >
            <Line height={'200px'} data={chartData.messages} options={{ maintainAspectRatio: false }} />
          </Card>
        </Grid2>

        <Grid3>
      

            <Card bordered={false} title="Usuários por Idade">
              <Pie data={chartData.age} />
            </Card>

            <Card bordered={false} title="Usuários por UF de origem">
              <Pie data={chartData.origem_uf} />
            </Card>

            <Card bordered={false} title="Usuários por cidade de origem">
              <Pie data={chartData.origem_city} />
            </Card>

            <Card bordered={false} title="Usuários por UF de destino">
              <Pie data={chartData.destino_uf} />
            </Card>

            <Card bordered={false} title="Usuários por cidade de destino">
              <Pie data={chartData.destino_city} />
            </Card>

        </Grid3>
      </div>
    </>
  );
};

export default Dashboard;
