import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs, DatePicker, Select, Input, Alert, Space } from "antd";
import { api } from "../../../core/services/api";
import { SearchOutlined } from '@ant-design/icons';
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  GridFilters,
  Filter,
  FilterLabel,
  Title,
} from "../styles";
import moment from "moment";
import { env } from "../../../enviroment";
import dayjs, { Dayjs } from 'dayjs';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

interface ComponentProps {
    reload: () => void;
    closeModal: () => void;
}

const ModalCreateRoomAds: React.FC<ComponentProps> = ({ reload, closeModal }) => {

    const [totalRooms, setTotalRooms] = useState<any>(0);

    const iniDate = dayjs().subtract(2, 'years').format('YYYY-MM-DD');
    const iniEndDate = dayjs().format('YYYY-MM-DD');
  
    const [dataFilter, setDataFilter] = useState<any>({ini: iniDate, end: iniEndDate});
    const [travelDataFilter, setTravelDataFilter] = useState<any>({ini: '', end: ''});
    const [destinationDataFilter, setDestinationDataFilter] = useState<any>('');
    const [origemDataFilter, setOrigemDataFilter] = useState<any>('');
    const [message, setMessage] = useState<any>('');
    const [linkTitle, setLinkTitle] = useState<any>('');
    const [link, setLink] = useState<any>('');
    const [image, setImage] = useState<any>('');
    const [filters, setFilters] = useState<any>({
      dateIni: iniDate,
      dateEnd: iniEndDate,
      dateTravelIni: '',
      dateTravelEnd: '',
      origemState: '',
      origemCity: '',
      destinationState: '',
      destinationCity: '',
    });
  

    useEffect(() => {
    
        console.log(filters);
    
        api.get("metrics/origem")
          .then((response:any) => {
            const apiData = response.data; 
            const origemData:any = [];
            var lastState = '';
            apiData.forEach((index:any, value:any) => {
              if(!(index.city === null) && !(index.state === null)) {
                if (index.state !== lastState) {
                  origemData.push({value: index.state, label: index.state});
                  lastState = index.state;
                }
                origemData.push({value: index.state + "-" + index.city, label: index.state + " > " + index.city});
              }
            });
            setOrigemFilter(origemData);
          });
    
        api.get("metrics/destinations")
          .then((response:any) => {
            const apiData = response.data; 
            const destionationData:any = [];
            var lastState = '';
            apiData.forEach((index:any, value:any) => {
              if(!(index.city === null) && !(index.state === null)) {
                if (index.state !== lastState) {
                  destionationData.push({value: index.state, label: index.state});
                  lastState = index.state;
                }
                destionationData.push({value: index.state + "-" + index.city, label: index.state + " > " + index.city});
              }
            });
            setDestinationFilter(destionationData);
          });
    
          api.get("room_ads/room-filters-count", { params: filters })
            .then((response:any) => {
              const count = response.data.total; 
              setTotalRooms(count);
              //setDestinationFilter(destionationData);
            });
    
      }, [filters]);
    





    const [origemFilter, setOrigemFilter] = useState<any>([
      {
        value: '',
        label: 'Todos',
      },
    ]);
  
    const [destinationFilter, setDestinationFilter] = useState<any>([
      {
        value: '',
        label: 'Todos',
      },
    ]);

    function handleDateChange(dates: any, dateStrings: [string, string]) {
        if(dates === null) {
          setDataFilter({ini: iniDate, end: iniEndDate});
        }else{
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
    
          const ini_year = startDate.getFullYear();
          const ini_month = String(startDate.getMonth() + 1).padStart(2, '0');
          const ini_day = String(startDate.getDate()).padStart(2, '0');
    
          const end_year = endDate.getFullYear();
          const end_month = String(endDate.getMonth() + 1).padStart(2, '0');
          const end_day = String(endDate.getDate()).padStart(2, '0');
    
          setDataFilter({ini: `${ini_year}-${ini_month}-${ini_day}`, end: `${end_year}-${end_month}-${end_day}`});
        }
      }
      function handleTravelDateChange(dates: any, dateStrings: [string, string]) {
        if(dates === null) {
          setTravelDataFilter({ini: '', end: ''});
        }else{
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
    
          const ini_year = startDate.getFullYear();
          const ini_month = String(startDate.getMonth() + 1).padStart(2, '0');
          const ini_day = String(startDate.getDate()).padStart(2, '0');
    
          const end_year = endDate.getFullYear();
          const end_month = String(endDate.getMonth() + 1).padStart(2, '0');
          const end_day = String(endDate.getDate()).padStart(2, '0');
    
          setTravelDataFilter({ini: `${ini_year}-${ini_month}-${ini_day}`, end: `${end_year}-${end_month}-${end_day}`});
        }
      }
      const onChangeDestination = (value: string) => {
        setDestinationDataFilter(value);
      };
    
      const onChangeOrigem = (value: string) => {
        setOrigemDataFilter(value);
      }
      
      const onSearch = (value: string) => {
        console.log('search:', value);
      };

      const filterOption = (input: string, option?: { label: string; value: string }) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  
      const selectFilters = async () => {

        var date = dataFilter;
        var travel_date = travelDataFilter;
        var origem = origemDataFilter;
        var destination = destinationDataFilter;
    
        // faz o split da origem separando por '-' para pegar o estado e a cidade
        var origemCity = '';
        var origemState = '';
        
        if (origem !== '' && origem !== undefined) {
          var origem_split = origem.split('-');
          if (origem_split.length > 1) {
            origemState = origem_split[0];
            origemCity = origem_split[1];
          }else{
            origemState = origem_split[0];
          }
        }
    
        // faz o split do destino separando por '-' para pegar o estado e a cidade
        var destinationCity = '';
        var destinationState = '';
        if (destination !== '' && destination !== undefined) {
          var destination_split = destination.split('-');
          if (destination_split.length > 1) {
            destinationState = destination_split[0];
            destinationCity = destination_split[1];
          }
        }
    
        setFilters({
          dateIni: date.ini,
          dateEnd: date.end,
          dateTravelIni: travel_date.ini,
          dateTravelEnd: travel_date.end,
          origemState: origemState,
          origemCity: origemCity,
          destinationState: destinationState,
          destinationCity: destinationCity,
        })
      }

      
    const createRoomAds = () => {
        if(message === '') {
            alert('Escreva a mensagem');
            return;
        }
        
        const data = new FormData();
        data.append('message', message);
        data.append('linkTitle', linkTitle);
        data.append('link', link);
        data.append('image', image); 

        // transforma o filters em query params para enviar para a api
        var filters_query = `?dateIni=${filters.dateIni}&dateEnd=${filters.dateEnd}&origemState=${filters.origemState}&origemCity=${filters.origemCity}&destinationState=${filters.destinationState}&destinationCity=${filters.destinationCity}`;

        api.post('room_ads/new'+filters_query, data)
            .then((response:any) => {
                alert('Disparo realizado com sucesso');
                closeModal();
                reload();
            })
            .catch((error:any) => {
                alert('Ouve algum erro' + error);
                console.log('Error: ', error);
            });
    }

    return (
        <Modal
          title="Novo disparo de room ads"
          onCancel={closeModal}
          visible={true}
          className="large-modal" 
          footer={[
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button type="primary" onClick={createRoomAds}>Disparar</Button>
                <Button key="close" onClick={closeModal}>
                Fechar
                </Button>
            </div>
          ]}
        >
        <Space direction="vertical" style={{ width: '100%', marginBottom: 15 }}>
          <Alert message="Os dados para filtragem abaixo são baseados no dono da room" type="warning" />
        </Space>
          <GridFilters>
            <Filter>
              <FilterLabel>Data do registro:</FilterLabel>
              <RangePicker 
                onChange={handleDateChange} />
            </Filter>
            <Filter>
              <FilterLabel>Viaja em:</FilterLabel>
              <RangePicker 
                onChange={handleTravelDateChange} />
            </Filter>
            <Filter>
              <FilterLabel>Origem:</FilterLabel>
              <Select
                showSearch
                id={'origem'}
                placeholder="Todos"
                optionFilterProp="children"
                onChange={onChangeOrigem}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{width: '100%'}}
                options={origemFilter}
              />
            </Filter>
            <Filter>
              <FilterLabel>Destino:</FilterLabel>
              <Select
                showSearch
                id={'destination'}
                placeholder="Todos"
                optionFilterProp="children"
                onChange={onChangeDestination}
                onSearch={onSearch}
                filterOption={filterOption}
                style={{width: '100%'}}
                options={destinationFilter}
              />
            </Filter>
            <Filter>
              <Button onClick={selectFilters} style={{width: "100%", marginTop: 20}} type="primary" icon={<SearchOutlined />}>Atualizar</Button>
            </Filter>
            <Filter>
                <p style={{fontSize: '20px', textAlign: 'center'}}>Rooms: {totalRooms}</p>
            </Filter>
          </GridFilters>
          {/* input para mensagem*/}
          <p>Mensagem</p>
          <TextArea rows={4} onChange={(value) => {setMessage(value.target.value)}} />
          <p>Titulo link</p>
          <Input onChange={(value) => {setLinkTitle(value.target.value)}}/>
          <p>Link</p>
          <Input onChange={(value) => {setLink(value.target.value)}}/>
          <p>Imagem (proporção 1:1):</p>
          <Input type='file'
            onChange={e => {
              const file = e.target.files?.[0];
              if (file) {
                setImage(file);
              }
            }} 
            accept="image/*"/>
        </Modal>
    )
}

export default ModalCreateRoomAds;