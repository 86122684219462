import {Divider, Dropdown, Layout, Menu, Select, Tooltip} from 'antd';
import React, { useContext, useState} from 'react';
import {
    ASide,
    BoxBread,
    BoxContent,
    BoxNotify,
    HeaderContainer,
    LargeLogo,
    LogoContainer,
    MainContent,
    MainHeader,
    MenuContainer,
    MenuItem,
    PersonalInfo,
    PersonalInfoContainer,
    PersonalName,
    PersonalRole,
    SmallLogo
} from "./styles";
import {
    LoginOutlined,
    LogoutOutlined,
    FileDoneOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    WarningOutlined,
    ClusterOutlined,
} from '@ant-design/icons'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {IconsAssets} from "../../../assets/index";
import {  useAuth } from '../../../core/context/AuthContext';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [collapsed, setCollapsed] = useState(false)
    const location = useLocation()
    const {pathname} = location
    const pathnames = pathname.split("/").filter((item: any) => item)
    const navigate = useNavigate();
    const { logout } = useAuth();

    const changeMenu = () => {
        collapsed ? setCollapsed(false) : setCollapsed(true)
    }

    const menu = (
        <Menu>
          <Menu.Item key="2" icon={<LogoutOutlined />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      );

    return (
        <Layout>
            <ASide
                collapsible
                onCollapse={changeMenu}
                collapsed={collapsed}
                reverseArrow={true}
                trigger={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            >
                <LogoContainer>
                {collapsed ?
                        <SmallLogo src={IconsAssets.smileLogo.toString()} alt="logo myhostel"/> :
                        <LargeLogo src={IconsAssets.smileLogo.toString()} alt="logo myhostel"/>
                    }
                    
                </LogoContainer>

                {!collapsed ?
                    <PersonalInfo>
                        <PersonalInfoContainer>
                        <PersonalName >Myhostel</PersonalName> 
                            <PersonalRole>Administrativo</PersonalRole>
                        </PersonalInfoContainer>
                    </PersonalInfo>: <></>
                }
                <MenuContainer selectedKeys={['/' + pathnames[0]]} mode="inline">
                    <MenuItem key={"/dashboard"} icon={<PieChartOutlined/>}>
                        <Link to={"/dashboard"}>Inicio</Link>
                    </MenuItem>
                    <MenuItem key={"/user-list"} icon={<UsergroupAddOutlined />}>
                        <Link to={"/user-list"}>Usuários</Link>
                    </MenuItem>
                    <MenuItem key={"/complaint"} icon={<WarningOutlined />}>
                        <Link to={"/complaint"}>Reclamações</Link>
                    </MenuItem>
                    <MenuItem key={"/room-ads"} icon={<ClusterOutlined />}>
                        <Link to={"/room-ads"}>Room Ads</Link>
                    </MenuItem>
                    <MenuItem key={"/admin-users"} icon={<UserOutlined/>}>
                        <Link to={"/admin-users"}>Admins</Link>
                    </MenuItem>
                    {false && (
                        <MenuItem key={"/logs"} icon={<FileDoneOutlined/>}>
                            <Link to={"/logs"}>Registro de Logs de User</Link>
                        </MenuItem>
                    )}
            
                </MenuContainer>
            </ASide>
            <Layout style={{minHeight: "100vh"}}>
                <MainHeader>
                    <HeaderContainer>
                        <BoxBread>
                            {/* <BreadCrumb location={location}/> */}
                        </BoxBread>
                        <BoxNotify>
                            <Divider type="vertical" style={{height: "100%", marginLeft: 20, marginRight: 20}}/>
                            <div className='profile'>
                                <Dropdown overlay={menu}>
                                    <UserOutlined/>
                                </Dropdown>
                            </div>
                        </BoxNotify>
                    </HeaderContainer>
                </MainHeader>
                <MainContent>
                    <BoxContent>
                        {children}
                    </BoxContent>
                </MainContent>
            </Layout>
        </Layout>
    );
}

export default MainLayout;