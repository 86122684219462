import React from 'react';

// import { Container } from './styles';

const Logs: React.FC = () => {
  return (<>
   text
  </>);
}

export default Logs;