import  styled  from "styled-components";

export const Container = styled.div`
  padding: 20px;
  display: grid;
  gap: 10px;  
  overflow-y: auto;
  
`;

export const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfilePhotoContainer = styled.div`
  width: 50%;
  height: 200px;
  margin: auto;
  margin-bottom: 5%;
  background-color: #D3D3D3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProfileDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NameContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const OtherInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
`;

export const HalfWidthCard = styled.div`
  width: 50%;
`;

export const Card = styled.div`
  background-color: #FFF;
  box-shadow:   0px 0px 2px #000;
  padding: 10px;
  margin: 10px;  // Adicionando a margem /* width: calc(100% - 10px);  // Ajustando a largura para considerar a margem */
  overflow: hidden;
`;

export const CardTitle = styled.p`
  text-transform: uppercase;
  color: #000;
`;

export const NameText = styled.p`
  font-size: 40px;
  color: #000;
`;