import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Table, Tabs } from "antd";
import { api } from "../../core/services/api";
import {
  Card,
  Container,
  NameContainer,
  ProfilePhoto,
  ProfilePhotoContainer,
  Title,
} from "./styles";
import moment from "moment";
import { env } from "../../enviroment";

const { TabPane } = Tabs;

const Complaint: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState<any>(null);
  const [complaint, setComplaint] = useState<any>([]);

  const showModal = (complaint: any) => {
    setSelectedComplaint(complaint);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedComplaint(null);
    setIsModalVisible(false);
  };

  const handleReject = async (complaintId: string) => {
    try {
      const response = await api.post(`/complaint/${complaintId}/resolve`, { resolution: false });

      if (response.status === 200) {
        const updatedComplaint = complaint.map((currenComplaint: any) =>
            currenComplaint.id === complaintId ? { ...complaint, accept: false } : currenComplaint
        );
        setComplaint(updatedComplaint);

        alert("Reclamação reprovada com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao reprovar o reclamação:", error);
      alert("Erro ao reprovar o reclamação. Tente novamente.");
    }
  };

  const handleApprove = async (complaintId: string) => {
    try {
        const response = await api.post(`/complaint/${complaintId}/resolve`, { resolution: true });

      if (response.status === 200) {
        const updatedComplaint = complaint.map((currenComplaint: any) =>
            currenComplaint.id === complaintId ? { ...complaint, accept: true } : currenComplaint
        );
        setComplaint(updatedComplaint);

        alert("Reclamação aprovada com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao aprovar o reclamação:", error);
      alert("Erro ao aprovar o reclamação. Tente novamente.");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Descrição",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Ação",
      dataIndex: "action",
      render: (text: any, user: any) => {
          return (
            <>
             {!user.approved && (
            <Button
              onClick={() => handleApprove(user.id)}
              style={{ marginLeft: "10px" }}
            >
              Aprovar
            </Button>
          )}
            {!user.rejected && (
            <Button
              onClick={() => handleReject(user.id)}
              style={{ marginLeft: "10px" }}
            >
              Rejeitar
            </Button>
          )}
              <Button
                onClick={() => showModal(user)}
                style={{ marginLeft: "10px" }}
              >
                Ver Detalhes
              </Button>
            </>
          );
      },
    },
  ];

  useEffect(() => {
    api.get("/complaint/list").then((response: any) => {
      setComplaint(response.data);
    });
  }, []);

  const acceptCopmlaint = complaint.filter(
    (complaint: any) => complaint.accept === true
  );
  const pendingComplaint = complaint.filter(
    (complaint: any) => complaint.accept === null
  );
  const rejectedComplaint = complaint.filter(
    (complaint: any) => complaint.accept === false
  ); 

  const selectedUserInitialDate = moment(selectedComplaint?.userComplain?.initialDate).format(
    "DD/MM/YYYY"
  );
  const selectedUserFinalDate = moment(selectedComplaint?.userComplain?.finalDate).format(
    "DD/MM/YYYY"
  );

  return (
    <>
      <Tabs defaultActiveKey="2">
        <TabPane tab="Aguardando" key="2">
          <Table dataSource={pendingComplaint} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Aceitos" key="1">
          <Table dataSource={acceptCopmlaint} columns={columns} rowKey="id" />
        </TabPane>
        <TabPane tab="Rejeitados" key="3">
          <Table dataSource={rejectedComplaint} columns={columns} rowKey="id" />
        </TabPane>
      </Tabs>

      <Modal
        title="Detalhes da reclamação"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Fechar
          </Button>,
        ]}
      >
        {selectedComplaint && (
          <Container>
          <Col span={24}>
            <Title>Reclamação</Title>
          </Col>
          <Col span={24}>
            <p>Feita por: {selectedComplaint.user.name}</p>
            <p>Email: {selectedComplaint.user.email}</p>
            <p>Descrição: {selectedComplaint.message}</p>
          </Col>
          <Col span={24}>
            <Title>Usuário Alvo</Title>
              <ProfilePhotoContainer>
                <ProfilePhoto src={`${env.api}users/${selectedComplaint.userComplain.id}/photo`} />
              </ProfilePhotoContainer>
            </Col>

            {selectedComplaint.userComplain.videoVerified != 0 && (
              <Row>
              <Col span={24}>
                <label htmlFor="">Video para verificação: </label>
                <Card>
                  <video width='100%' controls >
                        <source src={`${env.api}users/${selectedComplaint.userComplain.id}/video-verification`} type="video/mp4"/>
                  </video>
                </Card>
              </Col>
            </Row>
            )}

            <Col span={24}>
              <label htmlFor="">Email:</label>
              <Card>
                <NameContainer>{selectedComplaint.userComplain.email}</NameContainer>
              </Card>
            </Col>

            <Col span={24}>
              <label htmlFor="">Nome:</label>
              <Card>
                <NameContainer>{selectedComplaint.userComplain.name}</NameContainer>
              </Card>
            </Col>

            <Row>
              <Col span={12}>
                <label htmlFor="">Idade: </label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.birthYear}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Room:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.id}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Messagem Chave:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.description}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Cidade de origem:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.city}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">UF de origem:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.state}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Cidade de Destino:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.destinationCity}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">UF de Destino:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.destinationState}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">De:</label>
                <Card>
                  <NameContainer>{selectedUserInitialDate}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Até:</label>
                <Card>
                  <NameContainer>{selectedUserFinalDate}</NameContainer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor="">Intro:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.intro}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <label htmlFor="">Profissão:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.profession}</NameContainer>
                </Card>
              </Col>
              <Col span={12}>
                <label htmlFor="">Vibes:</label>
                <Card>
                  <NameContainer>{selectedComplaint.userComplain.vibes}</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Indicações:</label>
                <Card>
                  <NameContainer>0</NameContainer>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <label htmlFor="">Links : </label>
                <Card>
                  <NameContainer>
                      <span>Instagram: {selectedComplaint.userComplain.instagram}</span> <br />
                      <span>Linkedin: {selectedComplaint.userComplain.linkedin}</span> <br />
                      <span>Facebook: {selectedComplaint.userComplain.facebook}</span> <br />
                  </NameContainer>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </Modal>
    </>
  );
};

export default Complaint;
